import React from 'react'



class WhatMake extends React.Component {


  render() {

    return (
      <section className="icon whitelabel-use smart pt-100 secureof">
        <div className="container">
          <h2 className="main-heading">Smart Contract Applications <span className='bluecolor'>We Offer</span></h2>
          <p className='text-center'>We assure you that we will provide smart contract development services that support various applications in the crypto field.</p>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                Smart Contract For NFTs
                  </h3>
                <p className="pharagraph">Our team specializes in building NFT minting, gaming, and P2E platforms with smart contracts to automate secured execution. We integrate smart contracts for NFT exchange platforms for seamless trading, and auctioning NFTs. Our smart contracts assure a secured immutability feature for NFT ownership transfer, fractional ownership, and NFT transactions in exchanges.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Smart Contract For DEX
                </h3>
                <p className="pharagraph">Our smart contract development services are highly tamper-proof, and flexible to implement into the DEX platforms. The smart contracts are widely utilized to automate the crypto token swaps, staking, and data security of DeFi users. Our smart contracts are potential enough to eradicate the entry of third parties, hackers, or scammers and also ensure seamless transactions.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Smart Contract For Tokens
                </h3>
                <p className="pharagraph">We implement cutting-edge smart contracts for crypto token creation to integrate complex features. Integrating smart contracts can enhance transferability, transparency, and accessibility. Our smart contracts pave the way for newbie startups to implement crypto tokens for fundraising processes and help unlock new opportunities in the dynamic digital landscape.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Smart Contract For DeFi Yield Farming
                </h3>
                <p className="pharagraph">Like DEX, smart contracts are also utilized in creating DeFi Yield Farming platforms. These smart contracts reward distribution, token execution, and staking processes in the platforms. Users can stake or lock their assets with these secured smart contracts in the Decentralized and yield farming platforms. This enriches the sustainability and growth of the platform in the crypto field.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhatMake