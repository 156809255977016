import React from 'react'



class Whatis extends React.Component {

  render() {

    return (
      <section className="whatis pt-0">                                                                                             
        <div className='gray-bg'>
        <div className="container">
                <h2 className="heading-h2 text-center"><span className='bluecolor'>Coinsclone </span>- Smart Contract Development Company </h2>
            <div className="text-center">
              <p className="pharagraph text-center">Coinsclone is a renowned smart contract development company that offers end-to-end smart contract development services from design to deployment with perfection. Our team also helps you to design, and deploy self-executing codes for smart contracts for your business requirements. Our smart contracts are highly potential enough to execute the predefined conditions without any bugs or errors.
              </p>
              <p className="pharagraph text-center">We won’t leave you behind even after the development process is completed. Our developers offer updates, upgrade services, security audits, and feature integration at regular intervals. In the dynamic crypto market, we get the limelight with our innovative solutions and potential portfolio.
              </p>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis