import React from "react"

class Benifits extends React.Component {

  render() {
    return (
      <section className="dapps smart pt-100 mb-0">
        <div className="container">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Benefits Of </span>Our Smart Contract Development</h2>
            <p className='text-center'>Our smart contract development will accelerate your business and amplify higher impacts. Harness the business perks of smart contract creation.</p>
          <div className="row mb-3">
                    <div className='d-flex flex-wrap justify-content-center px-0'>
                        <div className='fea-card'>
                            <h3>Enhanced Security</h3>
                            <p className='pharagraph'>We utilize advanced-level security protocols and plugins for creating smart contracts. Our team offers tamper-proof smart contracts that suit all kinds of industries and applications.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Regular Updates</h3>
                            <p className='pharagraph'>Our team updates you regularly regarding the process of creating smart contracts. So that you can complete your smart contract creation in a hassle-free manner. Our technical team unleashes creativity at its core.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Assures Quality</h3>
                            <p className='pharagraph'>We assure to develop a smart contract with high-level quality that will represent your brand. Our smart contracts are flexible & automated, and they also reduce the involvement of third parties.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Flexible Scalability</h3>
                            <p className='pharagraph'>We specialize in integrating complex features and functionalities that will suit the scalability of your business needs. Our team offers efficient resources to accommodate your varying levels of demand and capacity.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Maintains Privacy</h3>
                            <p className='pharagraph'>Our team safeguards your data and acts as a trusted partner for smart contract development. We assure to create a smart contract without letting anyone know about your business strategies and ideas.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Customized Solutions</h3> 
                            <p className='pharagraph'>Our designers and developers will get your choice of layout, features, and functionalities depending on your specifications. We work closely with you to understand and analyze your specific requirements in smart contract development.</p>
                        </div>
                    </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Benifits
