import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2">Why Choose Coinsclone As Your <span className="bluecolor">Smart Contract Development Company?</span></h2>
              <p className="pharagraph">Coinsclone, as a reputed smart contract service provider, offers exemplary services to take your crypto business to the next level. We possess an exponential developer team to offer you the best smart contract development services. We craft your smart contracts with extraordinary care and perfection. Our team offers personalized and efficient solutions for smart contracts that will surely suit any kind of industry.</p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/smart/why-choose-smart-contract-development.webp"
                alt="best Asset Tokenization Platform development company image1"
                placeholder='none'
                className='mt-3 mb-3'
                width={532}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">We assure you that we offer potential smart contract development that ignites your business seamlessly. Our team helps you to optimize the smart contracts based on your needs and choices. We conduct thorough research, testing, and debugging for a potential smart contract integration. We serve you with the best-ever smart contract creation that reaps huge profits and long-lasting success.
              </p>
              <b>Never settle for less when you can get the best</b>
              <ul className='mt-3'>
                <li>Assure to provide high-level Quality</li>
                <li>Will deliver on time</li>
                <li>Customer satisfaction is our motto</li>
                <li>Thorough Research and Analyzation</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose