import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


const FeatureBenefits = () => {


  return (
    <section className="customs smart pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center">Our Smart Contract <span className="bluecolor">Development Process</span></h2>
            <p className='text-center'>Ignite your business success with our efficient creation process. Let our experience be your guide for developing smart contracts at ease.</p>
            <div className='d-flex flex-wrap justify-content-center top'>
                <div className='custom-blk'>
                    <h3>Resource Gathering</h3>
                    <p class="pharagraph">Our team identifies the purpose and the resources for creating smart contracts. At first, we understand your crypto purpose's conditions and functionalities. Our developer team defines the roadmap before creation.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Smart Contract Design</h3>
                    <p class="pharagraph">We are well-versed in designing modern and responsive smart contracts for any business need. Our team designs the entire architecture, conditions to be integrated, parameters, and values.</p>
                </div>
                <div className='custom-blk'> 
                    <h3>Development Phase</h3>
                    <p class="pharagraph">Our team chooses the best solidity programming language and tools for writing smart contracts. We integrate the necessary parameters into the contract code to automate the execution.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Quality Check</h3>
                    <p class="pharagraph">We assure to double-check the robustness, correctness, and hassle-free execution of the smart contract. Our testing team will take responsibility for correcting the errors, bugs, and vulnerabilities.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Smart Contract Compile</h3>
                    <p class="pharagraph">Our solidity architects will utilize exact compiler tools for converting the digital contract to bytecode for the specific blockchain. We optimize the smart contract to improve efficiency and to reduce the gas fees.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Deployment</h3>
                    <p class="pharagraph">We help you to deploy the smart contracts in the Mainnet of the blockchain network. Also, we verify whether the smart contract is deployed and it is accessible. Our team ensures the working of those digital agreements.</p>
                </div>
            </div>
        </div>
        <div className="mt-3 text-center">
                <ButtonComponent />
        </div>
      </div>
    </section>
  )
}

export default FeatureBenefits