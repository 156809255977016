import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/smart/Banner';
import Whatis from '../components/smart/Whatis';
import DevService from '../components/smart/DevServices';
import WhyChoose from '../components/smart/WhyChoose';
import FaqSection from '../components/smart/FaqSection';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import Benificial from '../components/smart/Benificial';
import TechStack from '../components/smart/TechStack';
import FeatureBenefits from '../components/smart/FeatureBenefits';
import VariousBlock from '../components/smart/VariousBlock';
import WhatMake from '../components/smart/WhatMake';
import Benifits from '../components/smart/Benifits';



const SmartContract = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/smart-contract-development-company/" />
        <title>Smart Contract Development company | Smart Contract Solutions</title>
        <meta name="description" content="Coinsclone is a leading smart contract development company that offers a wide range of digital contract development services for newbie startups and entrepreneurs." />
        <meta name="keywords" content="Smart Contract Development Company, Smart Contract Development Services" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Smart Contract Development company | Smart Contract Solutions" />
        <meta property="og:description" content="Coinsclone is a leading smart contract development company that offers a wide range of digital contract development services for newbie startups and entrepreneurs." />
        <meta property="og:url" content="https://www.coinsclone.com/smart-contract-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/smart/smart-contract-development-company.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is a leading smart contract development company that offers a wide range of digital contract development services for newbie startups and entrepreneurs." />
        <meta name="twitter:title" content="Smart Contract Development company | Smart Contract Solutions" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/smart/smart-contract-development-company.webp" />
      </Helmet> 
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Smart Contract Development Company</span>
        </div>
      </div>
      <Whatis />
      <VariousBlock />
      <FeatureBenefits />
      <Benifits />
      <Benificial />
      <WhatMake />
      <DevService />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default SmartContract
