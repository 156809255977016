import React from 'react'



class VariousBlock extends React.Component {


  render() {

    return (
      <section className="icon whitelabel-use tokenize pt-100 secureof">
        <div className="container">
          <h2 className="main-heading">Our Smart Contract <span className='bluecolor'>Development Services</span></h2>
          <p className='text-center'>We offer various client-focused smart contract development services to retrieve exemplary results.</p>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                Consulting Services
                  </h3>
                <p className="pharagraph">Coinsclone offers you the optimal solutions for your business with high-end efficiency and security. We bring out the best consulting services for developing seamless smart contracts. We offer our insights regarding blockchain, solidity language, programming tools, integration of features, and design. Our team offers you potential insights regarding current market trends to make to stay ahead.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Optimization Services
                </h3>
                <p className="pharagraph">Our technical team plans and builds better smart contracts to satisfy your requirements. Our team focuses on enhancing the smart contract to streamline the execution with the utmost perfection. We also customize the smart contract architecture that aligns with the needs of the specific industry usage. Occasionally, we optimize your smart contracts to rectify technical errors, mistakes, and misleading functionalities.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Auditing
                </h3>
                <p className="pharagraph">As a part of upgradation and pro-longed support, our developer team offers smart contract auditing services. We also assure to provide regular reports, insights, and recommendations to mitigate risk. Our testing team identifies the bugs, errors, security threats, and coding mistakes for better execution. We offer smart contract auditing services as a part to ensure the productivity and robustness of the smart contracts.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Seamless Smart Contract Development
                </h3>
                <p className="pharagraph">We utilize an advanced-level tech stack, and utilities to offer the best smart contracts for all kinds of business. We leverage to offer interoperable, immutable, and tamper-proof smart contracts that tend to fulfill the execution seamlessly. With our experienced developers, you can harness the potential of smart contracts to the peak.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default VariousBlock